<template>
  <div class="flix-form-group" v-if="credits">
    <div class="flix-form-group">
      <profileParts />
    </div>
    <div class="flix-form-group">
      <div class="flix-alert flix-alert-info"><span>{{ $t('message.creditsForSms') }} <router-link class="flix-html-a flix-html-strong flix-text-info" :to="{name: 'dashboardReminder', params: {id: 'all'}}">{{ $t('message.edit', {name: $tc('message.reminder', 2)})}}</router-link></span></div>
    </div>
    <div class="flix-form-group">
      <editBtn v-if="!variables.user.first_name" :settings="{block: true}" :onClick="function () { getEditProfile() }">
        <template v-slot:text>
          {{ $t('message.edit', { name: $t('message.profile') }) }}
        </template>
      </editBtn>
      <editBtn v-else :settings="{block: true}" :onClick="function () { getBuyCredits() }">
        <template v-slot:text>
          {{ $t('message.buyCredit') }}
        </template>
      </editBtn>
    </div>
    <div v-html="table" />
  </div>
</template>
<script>
export default {
  components: {
    profileParts () { return import('@/components/profile/parts') }
  },
  props: {},
  data () {
    return {
      credits: false,
      innovoices: false,
      variables: this.$getUserVariables(),
      table: false
    }
  },
  methods: {
    getEditProfile () {
      this.$router.push({ name: 'dashboardProfile', params: { id: 'profile' } })
    },
    getBuyCredits () {
      this.$router.push({ name: 'dashboardBuyCredits' })
    },
    getCoins () {
      this.$store.dispatch('getCredits', {
        user: this.variables.user.md5_id,
        callback: function () {
          this.credits = this.$store.getters.credits
        }.bind(this)
      })
    },
    getTable () {
      this.$flix_post({
        url: 'credit/get',
        data: { user: this.variables.user.md5_id },
        callback: function (ret) { this.table = ret.data[1] }.bind(this)
      })
    }
  },
  mounted () {
    this.getCoins()
    this.getTable()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
